export enum ROUTES {
    COACHING = '/coaching',
    WELCOME = '/coaching/welcome',
    ONBOARDING = '/coaching/onboarding',
    DOES_NOT_EXIST = '/does-not-exist',

    // user
    COACHEE_ONBOARDING = '/coaching/u/onboarding',
    SCHEDULE = '/coaching/u/schedule',
    COACHES = '/coaching/u/coaches',
    COACH = '/coaching/u/coaches/:id',

    // coach
    COACH_ONBOARDING = '/coaching/c/onboarding',
    MYSCHEDULE = '/coaching/c/myschedule',

    // admin
    UPLOAD_COACHEES = '/coaching/a/upload-coachees'
}
